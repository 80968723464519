const indexVowels = {
  kana: ['ア', 'カ', 'サ', 'タ', 'ナ', 'ハ', 'マ', 'ヤ', 'ラ', 'ワ'],
  hira: ['あ', 'か', 'さ', 'た', 'な', 'は', 'ま', 'や', 'ら', 'わ'],
};
const vowels = {
  ア: [
    { kana: 'ア', hira: 'あ' },
    { kana: 'イ', hira: 'い' },
    { kana: 'ウ', hira: 'う' },
    { kana: 'エ', hira: 'え' },
    { kana: 'オ', hira: 'お' },
  ],
  カ: [
    { kana: 'カ', hira: 'か', d: ['ガ'] },
    { kana: 'キ', hira: 'き', d: ['ギ'] },
    { kana: 'ク', hira: 'く', d: ['グ']},
    { kana: 'ケ', hira: 'け', d: ['ゲ'] },
    { kana: 'コ', hira: 'こ', d: ['ゴ'] },
  ],
  サ: [
    { kana: 'サ', hira: 'さ', d: ['ザ'] },
    { kana: 'シ', hira: 'し', d: ['ジ'] },
    { kana: 'ス', hira: 'す', d: ['ズ'] },
    { kana: 'セ', hira: 'せ', d: ['ゼ'] },
    { kana: 'ソ', hira: 'そ', d: ['ゾ'] },
  ],
  タ: [
    { kana: 'タ', hira: 'た', d: ['ダ'] },
    { kana: 'チ', hira: 'ち', d: ['ヂ'] },
    { kana: 'ツ', hira: 'つ', d: ['ヅ'] },
    { kana: 'テ', hira: 'て', d: ['デ'] },
    { kana: 'ト', hira: 'と', d: ['ド'] },
  ],
  ナ: [
    { kana: 'ナ', hira: 'な' },
    { kana: 'ニ', hira: 'に' },
    { kana: 'ヌ', hira: 'ぬ' },
    { kana: 'ネ', hira: 'ね' },
    { kana: 'ノ', hira: 'の' },
  ],
  ハ: [
    { kana: 'ハ', hira: 'は', d: ['バ', 'パ'] },
    { kana: 'ヒ', hira: 'ひ', d: ['ビ', 'ピ'] },
    { kana: 'フ', hira: 'ふ', d: ['ブ', 'プ'] },
    { kana: 'ヘ', hira: 'へ', d: ['ベ', 'ペ'] },
    { kana: 'ホ', hira: 'ほ', d: ['ボ', 'ポ'] },
  ],
  マ: [
    { kana: 'マ', hira: 'ま' },
    { kana: 'ミ', hira: 'み' },
    { kana: 'ム', hira: 'む' },
    { kana: 'メ', hira: 'め' },
    { kana: 'モ', hira: 'も' },
  ],
  ヤ: [
    { kana: 'ヤ', hira: 'や' },
    { kana: 'ユ', hira: 'ゆ' },
    { kana: 'ヨ', hira: 'よ' },
  ],
  ラ: [
    { kana: 'ラ', hira: 'ら' },
    { kana: 'リ', hira: 'り' },
    { kana: 'ル', hira: 'る' },
    { kana: 'レ', hira: 'れ' },
    { kana: 'ロ', hira: 'ろ' },
  ],
  ワ: [
    { kana: 'ワ', hira: 'わ' },
    { kana: 'ヲ', hira: 'を' },
    { kana: 'ン', hira: 'ん' },
  ],
};

export const letterMap = {
  indexVowels,
  vowels,
};
