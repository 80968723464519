import Vue from 'vue';
import Component from 'vue-class-component';
import { letterMap } from '@/libs/letterMap';
import { eventBus } from '@/eventBus';

@Component({
  props: {
    topTags: Object,
    selectedTags: Array,
  },
})
export default class TagChoiceMixin extends Vue {
  vowels = letterMap.vowels;
  indexVowels = letterMap.indexVowels;
  container = '#sai-box-tag-lists';
  activeVowelIdx = 0;
  tagChoiceHeight = 0;
  tops = {};

  get topTagsLoading() {
    return Object.keys(this.topTags).length === 0;
  }
  get displayIndexVowels() {
    return this.$store.state.constObj.TAG_INDEX_HIRAGANA
      ? this.indexVowels.hira
      : this.indexVowels.kana;
  }
  displayTags(v) {
    let tags = this.topTags[v.kana] || [];

    if (!v.d) {
      return tags;
    }

    const tagsWithD = v.d.reduce((a, c, idx, ary) => {
      return this.topTags[c] ? a.concat(this.topTags[c]) : a;
    }, []);
    return [...tags, ...tagsWithD];
  }
  displayFirstLetter(v) {
    return this.$store.state.constObj.TAG_INDEX_HIRAGANA ? v.hira : v;
  }
  async selectTag(tag) {
    await this.$store.dispatch('tagSearch/resetInput');
    await this.$store.dispatch('tagSearch/addSelectedTag', tag);
    this.$store.commit('tagSearch/setShow', true);

    if (!this.$store.state.user.isIphone) {
      setTimeout(() => {
        eventBus.$emit('selectNextTag');
      }, 0);
    }

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = await this.updateTicket(tag);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async updateTicket(tag) {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const historyActions = [
      { type: 'tag', value: 'list' },
      { type: '', value: 'search' },
    ];
    await this.$store.dispatch(
      'ticket/addHistoryActionFaqChannels',
      historyActions
    );
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    } else {
      let tagUsedSet = { id: tag.id, text: tag.text };
      if (tag.displayText) {
        tagUsedSet.label = tag.displayText;
      }
      this.$store.commit('ticket/addTagUsedSet', tagUsedSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    return this.generateTicket(startTime, query, tagActiveSet);
  }
  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      product_type: this.$store.state.productType,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };

    return newTicket;
  }
  scroll(idx) {
    this.$scrollTo(`#v_sai-search-box_${idx}`, { container: this.container });
  }
  generateVowelIdx(v) {
    return `v_sai-search-box_${this.indexVowels.kana.indexOf(v)}`;
  }
  isSelected(tag) {
    return (
      this.selectedTags
        .map(t => {
          return t.id;
        })
        .indexOf(tag.id) >= 0
    );
  }
  isIndexVowels(v) {
    return this.indexVowels.kana.indexOf(v) >= 0;
  }
  inScroll(idx) {
    return this.activeVowelIdx === idx;
  }
  handleScroll(e) {
    this.setIndexHeight();
    this.setActiveVowelIdx(e);
  }
  setIndexHeight() {
    const sciTagLists = document.getElementById('sai-box-tag-lists');

    // 親の上部からスクロール領域までの高さ
    const scrollTop = sciTagLists.offsetTop;

    for (var i = 0; i < this.indexVowels.kana.length; i++) {
      const key = `v_sai-search-box_${i}`;

      // 親の上部から Vowel までの高さ
      const vowelTop = document.getElementById(key).offsetTop;

      // スクロール領域から Vowel までの高さ
      const vowelScrollTop = vowelTop - scrollTop;

      this.tops[i] = vowelScrollTop;
    }
  }
  setActiveVowelIdx(e) {
    const scrollTop = e.target.scrollTop;
    const idx =
      Object.keys(this.tops)
        .filter(key => scrollTop + 1.5 >= this.tops[key])
        .pop() || 0;
    this.activeVowelIdx = parseInt(idx);
  }
  setTagChoiceHeight() {
    this.tagChoiceHeight = this.$refs.sciTagLists.offsetHeight;
  }

  updated() {
    this.setTagChoiceHeight();
    this.setIndexHeight();
  }
  mounted() {
    this.$nextTick(() => {
      this.$refs.sciTagLists.addEventListener(
        'scroll',
        e => {
          this.handleScroll(e);
        },
        false
      );
    });
    window.addEventListener('resize', () => {
      this.setTagChoiceHeight();
      this.setIndexHeight();
    });
  }
  beforeDestroy() {
    this.$refs.sciTagLists.removeEventListener(
      'scroll',
      e => {
        this.handleScroll(e);
      },
      false
    );
    window.removeEventListener('resize', () => {
      this.setTagChoiceHeight();
      this.setIndexHeight();
    });
  }
}
